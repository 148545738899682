import * as React from 'react';
import * as styles from '../pages/index.module.scss';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Brand from '../components/brand';
import Button from '../components/button';
import EmbedButton from '../components/embed-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from '../components/v2/footer';
import Header from '../components/v2/header';
import { Link } from 'gatsby';
import SEO from '../components/seo';
import Section from '../components/section';
import WistiaEmbed from '../components/utils/wistia-embed';
import { faSparkles } from '@fortawesome/pro-regular-svg-icons';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { graphql } from 'gatsby';

const Logo = ({ id }) => {
  console.log(id);
  switch (id) {
    case 'shopify':
      return (
        <svg
          width="110"
          height="125"
          viewBox="0 0 110 125"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#a)">
            <path
              d="M95.9 23.9c-.1-.6-.6-1-1.1-1s-9.3-.2-9.3-.2-7.4-7.2-8.1-7.9-2.2-.5-2.7-.3c0 0-1.4.4-3.7 1.1-.4-1.3-1-2.8-1.8-4.4-2.6-5-6.5-7.7-11.1-7.7-.3 0-.6 0-1 .1-.1-.2-.3-.3-.4-.5C54.7.9 52.1-.1 49 0c-6 .2-12 4.5-16.8 12.2-3.4 5.4-6 12.2-6.8 17.5-6.9 2.1-11.7 3.6-11.8 3.7-3.5 1.1-3.6 1.2-4 4.5-.3 2.5-9.5 73-9.5 73l76.4 13.2 33.1-8.2c-.1-.1-13.6-91.4-13.7-92m-28.7-7.1c-1.8.5-3.8 1.2-5.9 1.8 0-3-.4-7.3-1.8-10.9 4.5.9 6.7 6 7.7 9.1m-10 3.1c-4 1.2-8.4 2.6-12.8 3.9 1.2-4.7 3.6-9.4 6.4-12.5 1.1-1.1 2.6-2.4 4.3-3.2 1.8 3.5 2.2 8.4 2.1 11.8M49.1 4q2.1 0 3.6.9c-1.6.9-3.2 2.1-4.7 3.7-3.8 4.1-6.7 10.5-7.9 16.6-3.6 1.1-7.2 2.2-10.5 3.2C31.7 18.8 39.8 4.3 49.1 4"
              fill="#95BF47"
            />
            <path
              d="M94.8 22.9c-.5 0-9.3-.2-9.3-.2s-7.4-7.2-8.1-7.9c-.3-.3-.6-.4-1-.5V124l33.099-8.2s-13.5-91.3-13.6-92c-.1-.5-.6-.9-1.1-.9"
              fill="#5E8E3E"
            />
            <path
              d="m58 39.9-3.8 14.4s-4.3-2-9.4-1.6c-7.5.5-7.5 5.2-7.5 6.4.4 6.4 17.3 7.8 18.3 22.9.7 11.9-6.3 20-16.4 20.6-12.2.8-18.9-6.4-18.9-6.4l2.6-11s6.7 5.1 12.1 4.7c3.5-.2 4.8-3.1 4.7-5.1-.5-8.4-14.3-7.9-15.2-21.7-.7-11.6 6.9-23.4 23.7-24.4 6.5-.5 9.8 1.2 9.8 1.2"
              fill="#fff"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path fill="#fff" d="M0 0h109.5v124.5H0z" />
            </clipPath>
          </defs>
        </svg>
      );
    case 'squarespace':
      return (
        <svg
          width="125"
          height="102"
          viewBox="0 0 125 102"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.1546 54L65.6522 14.0188C68.3357 11.3024 71.9063 9.80875 75.7122 9.80875C79.5154 9.80875 83.086 11.301 85.7667 14.0174L88.844 17.1323L95 10.901L91.9227 7.786C87.5996 3.40999 81.843 1 75.7094 1C69.5787 1.00142 63.8207 3.41141 59.4976 7.78883L20 47.7686L26.1546 54Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M79 27.2992L72.8149 21.0771L33.1318 60.9991C27.5592 66.6051 18.492 66.6065 12.9207 61.0019C7.34808 55.3959 7.34808 46.273 12.9207 40.667L47.1635 6.22073L40.9783 0L6.73703 34.4463C-2.24568 43.4828 -2.24568 58.1876 6.73703 67.2226C11.0806 71.5922 16.8644 74 23.0242 74C29.1854 74 34.972 71.5907 39.3156 67.2212L79 27.2992Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M118.262 34.7761C113.92 30.4065 108.136 28 101.973 28C95.812 28.0014 90.0253 30.4079 85.6818 34.7789L46 74.7002L52.1823 80.9225L91.867 40.9998C97.441 35.3936 106.507 35.395 112.078 40.9983C114.773 43.7093 116.257 47.3183 116.257 51.1639C116.257 55.0108 114.773 58.6212 112.078 61.3322L77.8367 95.7792L84.0218 102L118.262 67.5545C127.246 58.5178 127.246 43.8127 118.262 34.7761Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M98.8455 48L59.3487 87.9812C53.801 93.5941 44.778 93.597 39.2331 87.9826L36.1559 84.8677L30 91.099L33.0772 94.214C37.4002 98.59 43.1567 101 49.2916 101C55.4223 100.999 61.1788 98.5886 65.5032 94.2112L105 54.2314L98.8455 48Z"
            fill="black"
          />
        </svg>
      );
    default:
      return null;
  }
};

const LandingPageTemplate = ({ pageContext, data }) => {
  const { metaTitle, metaDescription, title, subtitle, btnText, logo } = pageContext;

  const quizImage = getImage(data.quizImage);

  return (
    <>
      <SEO title={`${metaTitle}`} description={`${metaDescription}`} onIndex={true} />
      <Header btnTheme="outline" btnLabel="Start free trial" />
      <main className={styles.index}>
        <Section theme="white" style={{ padding: `5rem 0`, marginTop: `4.5rem` }}>
          {logo && (
            <div className="row justify-content-center">
              <div className="col-auto">
                <div className={styles.logos}>
                  <div className={styles.logoContainer}>
                    <div className={styles.logo} style={{ backgroundPosition: `0 -1216px` }}></div>
                  </div>
                  <div className={styles.logoContainer}>
                    {logo === 'squarespace' ? <Logo id="squarespace" /> : null}
                    {logo === 'shopify' ? <Logo id="shopify" /> : null}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-10 col-xl-9 text-center">
              <h1 className="color-black" style={{ marginBottom: `2.5rem` }}>
                {title}
              </h1>
            </div>
            <div className="col-md-8 col-lg-6 col-xl-5">
              <h2 className="text-body color-muted-foreground text-center">{subtitle}</h2>
              <form method="get" action="https://ai.tryinteract.com/create">
                <div
                  className={styles.inputContainer}
                  style={{ flexDirection: 'column', alignItems: 'center' }}>
                  <input
                    type="text"
                    placeholder="https://www.mysite.com"
                    name="website_url"></input>
                  <Button
                    theme="primary"
                    type="submit"
                    size="large"
                    style={{ gap: '8px', marginTop: '0.5rem' }}>
                    <FontAwesomeIcon icon={faSparkles} /> {btnText}
                  </Button>
                </div>
              </form>
              <p className={styles.subtext}>
                Already know what you want? <Link to="/plans/">Start from scratch</Link>
              </p>
            </div>
          </div>
          <div className="row" style={{ marginTop: '6rem' }}>
            <div className="col-lg-12">
              <div className={styles.videoContainer}>
                <WistiaEmbed hashedId="a0b08gfysm" playerColor="#7371fc" />
              </div>
            </div>
          </div>
        </Section>
        <Section theme="default" style={{ padding: `2rem 0 5rem 0` }}>
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-12 col-xl-10">
              <div className="row">
                <div className="col">
                  <p className="text-body color-black text-center">Trusted by leading businesses</p>
                </div>
              </div>
              <div className="row align-items-center justify-content-center justify-content-lg-between">
                <Brand id="gretchen-rubin" className={`col-auto ${styles.brand}`} />
                <Brand id="jennakutcher-color" className={`col-auto ${styles.brand}`} />
                <Brand id="melyssagriffin-color" className={`col-auto ${styles.brand}`} />
                <Brand id="redcross-color" className={`col-auto ${styles.brand}`} />
                <Brand id="atlassian" className={`col-auto ${styles.brand}`} />
                <Brand id="lush-color" className={`col-auto ${styles.brand}`} />
              </div>
            </div>
          </div>
        </Section>
        <Section theme="default" style={{ padding: `2rem 0 5rem 0` }}>
          <div className="row">
            <div className="col-12 text-center">
              <p className="heading-lg color-black" style={{ marginBottom: '1rem' }}>
                Empowering businesses since 2014 with proven results:
              </p>
            </div>
          </div>
          <div
            className={`row no-gutters align-items-center justify-content-center ${styles.metrics}`}>
            <div className="col-md-4">
              <p className={styles.number}>1.2B</p>
              <p className={styles.label}>quiz impressions</p>
            </div>
            <div className="col-md-4">
              <p className={styles.number}>400M</p>
              <p className={styles.label}>quizzes completed</p>
            </div>
            <div className="col-md-4">
              <p className={styles.number}>85M</p>
              <p className={styles.label}>leads generated</p>
            </div>
          </div>
        </Section>
        <Section theme="default" style={{ padding: `2rem 0 5rem 0` }}>
          <div
            className="row no-gutters align-items-stretch justify-content-center"
            style={{ borderRadius: '32px', overflow: 'hidden', background: `#edf0ff` }}>
            <div className="col-lg-6 col-xl-5 order-lg-1 text-md-center">
              <div className={styles.quizContainer}>
                <p
                  className="heading-xs-upper color-purple-500"
                  style={{ margin: '0', fontWeight: '700', fontSize: '14px' }}>
                  Take Quiz
                </p>
                <p
                  className="heading-lg color-primary-950 font-style-golos"
                  style={{ margin: `0`, marginTop: '1rem', fontWeight: '700' }}>
                  What quiz should you make for your business?
                </p>
                <p className="heading-sm color-primary-950" style={{ margin: `1rem 0 2rem 0` }}>
                  Find your perfect lead generation quiz. Unlock the power of a 40% conversion rate
                  that quizzes offer by finding the exact quiz type that will work for your
                  business.
                </p>
                <EmbedButton id="65414c9de4c0cb0014c537e5" disableCover={true}>
                  Find out now
                </EmbedButton>
              </div>
            </div>
            <div className="col-md-8 col-lg-6 col-xl-7 order-lg-0 text-center">
              <GatsbyImage image={quizImage} alt="Interact template screenshots" />
            </div>
          </div>
        </Section>
        <Section theme="default" style={{ padding: `2rem 0 5rem 0` }}>
          <div className="row justify-content-center">
            <div className="col-auto d-none d-sm-block">
              <div className={styles.integrations}>
                <div className={styles.integrationItem}>
                  <div
                    className={styles.icon}
                    aria-label="Klaviyo"
                    style={{ backgroundPosition: '0px -880px' }}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div
                    className={styles.icon}
                    aria-label="Zapier"
                    style={{ backgroundPosition: '0px -1720px' }}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div
                    className={styles.icon}
                    aria-label="Mailchimp"
                    style={{ backgroundPosition: '0px -960px' }}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div
                    className={styles.icon}
                    aria-label="Interact"
                    style={{ backgroundPosition: '0px -760px' }}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div
                    className={styles.icon}
                    aria-label="Convertkit"
                    style={{ backgroundPosition: '0px -240px' }}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div
                    className={styles.icon}
                    aria-label="Flodesk"
                    style={{ backgroundPosition: '0px -480px' }}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div
                    className={styles.icon}
                    aria-label="Constant Contact"
                    style={{ backgroundPosition: '0px -200px' }}></div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <h3 className="heading-xl color-black">Integrate quizzes with your existing tools</h3>
            </div>
            <div className="col-lg-6 col-xl-6 text-center">
              <p className="heading-sm">
                Identify the needs of your customers based on an assessment so they can see clearly
                how to begin working with your business.
              </p>
              <Button
                theme="outline"
                size="large"
                type="internal"
                href="/integrations"
                style={{ marginTop: '2rem' }}>
                Browse integrations
              </Button>
            </div>
          </div>
        </Section>
        <Section theme="default" style={{ padding: `2rem 0 5rem 0` }}>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <h3 className="heading-xl color-black">Our customers love Interact quizzes</h3>
            </div>
          </div>
          <div className="row" style={{ marginTop: '3rem' }}>
            <div className="col-md-6 col-lg-4">
              <div className={styles.testimonial}>
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <p className="text-body color-black">
                  THANK YOU! This has literally been on my list for years and this is going to make
                  it so easy to just get it out there.
                </p>
                <div className={styles.caption}>
                  <p className="text-body color-black">Kelsey K.</p>
                  <p className="text-body">Business Coach</p>
                </div>
              </div>
              <div className={styles.testimonial}>
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <p className="text-body color-black">Oh my goodness this is good!</p>
                <div className={styles.caption}>
                  <p className="text-body color-black">Heather F.</p>
                  <p className="text-body">Marketing Agency</p>
                </div>
              </div>
              <div className={styles.testimonial}>
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <p className="text-body color-black">
                  Such a clean design, heaps of integration options that I think my sutdents will
                  benefit the most from using Interact over competitors.
                </p>
                <div className={styles.caption}>
                  <p className="text-body color-black">Teago</p>
                  <p className="text-body">Business Coach</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className={styles.testimonial}>
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <p className="text-body color-black">
                  This is so much fun, and so easy and intuitive.
                </p>
                <div className={styles.caption}>
                  <p className="text-body color-black">Kemya S.</p>
                  <p className="text-body">Digital Marketing</p>
                </div>
              </div>
              <div className={styles.testimonial}>
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <p className="text-body color-black">
                  I found it really helpful in getting a head start on the quiz and felt like it
                  saved me a lot of time from having to manually build it from scratch.
                </p>
                <div className={styles.caption}>
                  <p className="text-body color-black">Lauren T.</p>
                  <p className="text-body">Interior Designer</p>
                </div>
              </div>
              <div className={styles.testimonial}>
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <p className="text-body color-black">
                  I love it, and I am excited about using it for my client work as well as my lead
                  generation.
                </p>
                <div className={styles.caption}>
                  <p className="text-body color-black">Marie P.</p>
                  <p className="text-body">Entrepreneur</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 d-none d-lg-block">
              <div className={styles.testimonial}>
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <p className="text-body color-black">
                  Creating personalized quizzes to generate leads for my offers was surprisngly
                  easy.
                </p>
                <div className={styles.caption}>
                  <p className="text-body color-black">Connor H.</p>
                  <p className="text-body">Ditigal Creator</p>
                </div>
              </div>
              <div className={styles.testimonial}>
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <p className="text-body color-black">
                  I just made a quiz in 5 minutes. It's pretty cool. And I had zero ideas of what I
                  wanted going into it.
                </p>
                <div className={styles.caption}>
                  <p className="text-body color-black">Emily</p>
                  <p className="text-body">Travel</p>
                </div>
              </div>
              <div className={styles.testimonial}>
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <p className="text-body color-black">
                  I am so impressed with this quiz! It really feels like you captured my brand...
                </p>
                <div className={styles.caption}>
                  <p className="text-body color-black">Christina C.</p>
                  <p className="text-body">Life Coaching</p>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Section theme="purple500" style={{ padding: `5rem 0` }}>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <h3 className="heading-xl color-white">Get started today</h3>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xl-6 text-center">
              <p
                className="heading-sm color-white text-center"
                style={{ marginTop: '0', marginBottom: '2rem' }}>
                Use our AI quiz maker to create a beautiful quiz for your brand in a minute.
              </p>
              <Button theme="white" size="large" href="https://ai.tryinteract.com/create">
                Make a quiz - for free
              </Button>
            </div>
          </div>
        </Section>
      </main>
      <Footer />
    </>
  );
};

export default LandingPageTemplate;

export const pageQuery = graphql`
  query {
    quizImage: file(relativePath: { eq: "landing-quiz.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, transformOptions: { cropFocus: CENTER })
      }
    }
  }
`;
